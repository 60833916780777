<template>
    <div>
        <page-title :heading="this.$t('analytic.lang_voucherGiftCardAnalytic')" :icon="icon"
                    :subheading="this.$t('analytic.lang_voucherGiftCardAnalytic')"></page-title>
        <div class="app-main__inner">
            <VoucherGiftCardComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import VoucherGiftCardComponent
        from "../../../../components/analytics/warewanlytics/VoucherGiftCard/VoucherGiftCardComponent";
    export default {
        name: "index",
        components: {VoucherGiftCardComponent, PageTitle},
        data: () => ({
            icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
        })
    }
</script>

<style scoped>

</style>